import React from 'react';
import Html from '../ass/assets/skills/html.png';
import Css from '../ass/assets/skills/css.png';
import Javascript from '../ass/assets/skills/javascript.png';
import ReactImg from '../ass/assets/skills/react.png';
import Tailwind from '../ass/assets/skills/tailwind.png';
import CMS from '../ass/assets/skills/cms.png';
import Firebase from '../ass/assets/skills/firebase.png';
import Python from '../ass/assets/skills/python.png';
import TAILWIND from '../ass/assets/skills/tailwind.png';
// import '../styles.css'; // Assuming your CSS file is named styles.css and located in the same directory as this file

const Skills = () => {
  return (
    <div name='skills' className='w-full h-screen bg-[#ecf0f3] text-[#1f2937] tracking-wide'>
      {/* Container */}
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div>
        <p className='text-xl tracking-widest uppercase text-[#5651e5]'>
          Skills
        </p>
        <h2 className='py-4'>What I Can Do</h2>
        </div>

        <div className='w-full grid grid-cols-2 sm:grid-cols-4 gap-4 text-center py-8'>
          <div className='shadow-md shadow-gray-400 hover:shadow-xl duration-500'>
            <img className='w-20 mx-auto' src={Html} alt="HTML icon" />
            <p className='my-4'>HTML</p>
          </div>
          <div className='shadow-md shadow-gray-400 hover:shadow-xl duration-500'>
            <img className='w-20 mx-auto' src={Css} alt="CSS icon" />
            <p className='my-4'>CSS</p>
          </div>
          <div className='shadow-md shadow-gray-400 hover:shadow-xl duration-500'>
            <img className='w-20 mx-auto' src={Javascript} alt="JavaScript icon" />
            <p className='my-4'>JavaScript</p>
          </div>
          <div className='shadow-md shadow-gray-400 hover:shadow-xl duration-500'>
            <img className='w-20 mx-auto' src={ReactImg} alt="React icon" />
            <p className='my-4'>React</p>
          </div>
          <div className='shadow-md shadow-gray-400 hover:shadow-xl duration-500'>
              <img className='w-20 mx-auto' src={TAILWIND } alt="TAILWIND  icon" />
              <p className='my-4'>Tailwind</p>
          </div>
          <div className='shadow-md shadow-gray-400 hover:shadow-xl duration-500'>
            <img className='w-20 mx-auto' src={Firebase} alt="Firebase icon" />
            <p className='my-4'>Firebase</p>
          </div>
          <div className='shadow-md shadow-gray-400 hover:shadow-xl duration-500'>
            <img className='w-20 mx-auto' src={CMS} alt="CMS icon" />
            <p className='my-4'>CMS</p>
          </div>
          <div className='shadow-md shadow-gray-400 hover:shadow-xl duration-500'>
            <img className='w-20 mx-auto' src={Python} alt="Python icon" />
            <p className='my-4'>Python</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
