import React from 'react';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import AboutImg from '../ass/assets/about.png'; // Import the image

const About = () => {
  return (
    <div id='about' className='w-full md:h-screen p-2 flex items-center py-16'>
      <div className='max-w-[1240px] m-auto md:grid grid-cols-3 gap-8'>
        <div className='col-span-2'>
          <p className='uppercase text-xl tracking-widest text-[#5651e5]'>
            About
          </p>
          <h2 className='py-4'>Who I Am</h2>
          <p className='py-2 text-gray-600'>
          Hey there! 👋 I'm Abdulsalam Muhammad Abubakar, a passionate front-end developer with a knack for crafting 
          beautiful and functional websites. With a solid foundation in front-end technologies,
           I bring creativity, innovation, and technical expertise to every project I undertake.
          </p>
          <p className='py-2 text-gray-600'>
          <h2 className='py-4'>What I Do</h2>
          <ul>
            <li>
           <span className='font-semibold'> Front-End Development: </span>  I specialize in creating intuitive and responsive 
user interfaces using HTML, CSS, JavaScript, React, Tailwind and Firebase. Whether it's building a sleek landing page or a complex 
web application, I thrive on turning ideas into reality through code.
            </li>
            <li>
           <span className='font-semibold'>CMS Expertise:</span>  I have experience working with Content 
Management Systems (CMS) to develop dynamic and scalable websites. 
I leverage these platforms to streamline 
content management and enhance user experience.
            </li>
            <li>
           <span className='font-semibold'> Passionate Educator:</span> Beyond coding, I'm deeply passionate about sharing my knowledge 
and empowering others to succeed in the world of technology. I regularly teach students,
 both individually and through organized boot camps, helping them unlock their potential and achieve their goals.
            </li>
          </ul>





          </p>
         
        </div>
        <div className='w-full h-auto m-auto shadow-xl shadow-gray-400 rounded-xl flex items-center justify-center p-4 hover:scale-105 ease-in duration-300'>
          <img src={AboutImg} alt='About' className='rounded-xl' /> {/* Use img tag for the image */}
        </div>
      </div>
    </div>
  );
};

export default About;
