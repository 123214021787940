import React from 'react';
import { AiOutlinePhone, AiOutlineMail, AiOutlineEnvironment } from 'react-icons/ai';
import ContactImg from '../ass/assets/contact.jpg';
import { FaGithub, FaLinkedinIn } from 'react-icons/fa';
import { BsFillPersonLinesFill } from 'react-icons/bs';
import { HiOutlineChevronDoubleUp } from 'react-icons/hi';

const Contact = () => {
  return (
    <div id='contact' className='w-full lg:h-screen w-full h-screen text-center flex justify-center items-center p-4'>
      <div className='max-w-[1240px] m-auto px-2 py-16 w-full'>
        <p className='text-xl tracking-widest uppercase text-[#5651e5]'>
          Contact
        </p>
        <h2 className='py-4'>Get In Touch</h2>
        <div className='grid lg:grid-cols-5 gap-8'>
          {/* left */}
          <div className='col-span-3 lg:col-span-2 w-full h-full shadow-xl shadow-gray-400 rounded-xl p-4'>
            <div className='lg:p-4 h-full'>
              <div>
                <img
                  className='rounded-xl hover:scale-105 ease-in duration-300'
                  src={ContactImg}
                  alt='Contact Image'
                />
              </div>
              <div>
                <h2 className='py-2 flex text-center items-center justify-center'>Abdulsalam Muhammad Abubakar</h2>
                <p>Front-End Developer</p>
                <p className='py-4'>
                  I am available for freelance or full-time positions. Contact me and let&apos;s talk.
                </p>
              </div>
              <div>
                <p className='uppercase pt-8 font-bold'>Connect With Me</p>
                <div className='flex flex-col gap-4'>
                  <div className='flex gap-2 items-center'>
                    <FaLinkedinIn />
                    <a href='https://www.linkedin.com/in/abdulsalam-muhammad-abubakar-90375a1ba/' target='_blank' rel='noreferrer'>
                      LinkedIn
                    </a>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <FaGithub />
                    <a href='https://github.com/Abdul100-han' target='_blank' rel='noreferrer'>
                      GitHub
                    </a>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <AiOutlineMail />
                    <p>abdulmuhd857@gmail.com</p>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <AiOutlinePhone />
                    <p>08039688857</p>
                  </div>
                  <div className='flex gap-2 items-center'>
                    <AiOutlineEnvironment />
                    <p>Kano, Nigeria</p>
                  </div>
                  <div>
                    <BsFillPersonLinesFill />
                    <a href='/resume'>
                      {/* <div className='rounded-full shadow-lg shadow-gray-400 p-6 cursor-pointer hover:scale-110 ease-in duration-300'>
                        Resume
                      </div> */}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* right */}
          <div className='col-span-3 w-full h-auto shadow-xl shadow-gray-400 rounded-xl lg:p-4'>
            <div className='p-4'>
              <form action='https://getform.io/f/c80ef627-7727-4b19-ad58-312a6a445d55' method='POST' encType='multipart/form-data'>
                <div className='grid md:grid-cols-2 gap-4 w-full py-2'>
                  <div className='flex flex-col'>
                    <label className='uppercase text-sm py-2'>Name</label>
                    <input className='border-2 rounded-lg p-3 flex border-gray-300' type='text' name='name' />
                  </div>
                  <div className='flex flex-col'>
                    <label className='uppercase text-sm py-2'>Phone Number</label>
                    <input className='border-2 rounded-lg p-3 flex border-gray-300' type='text' name='phone' />
                  </div>
                </div>
                <div className='flex flex-col py-2'>
                  <label className='uppercase text-sm py-2'>Email</label>
                  <input className='border-2 rounded-lg p-3 flex border-gray-300' type='email' name='email' />
                </div>
                <div className='flex flex-col py-2'>
                  <label className='uppercase text-sm py-2'>Subject</label>
                  <input className='border-2 rounded-lg p-3 flex border-gray-300' type='text' name='subject' />
                </div>
                <div className='flex flex-col py-2'>
                  <label className='uppercase text-sm py-2'>Message</label>
                  <textarea className='border-2 rounded-lg p-3 border-gray-300' rows='10' name='message'></textarea>
                </div>
                <button className='w-full p-4 text-gray-100 mt-4'>Send Message</button>
              </form>
            </div>
          </div>
        </div>
        <div className='flex justify-center py-12'>
          <a href='/'>
            <div className='rounded-full shadow-lg shadow-gray-400 p-4 cursor-pointer hover:scale-110 ease-in duration-300'>
              <HiOutlineChevronDoubleUp className='text-[#5651e5]' size={30} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;
