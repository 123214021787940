import React from 'react';
import AdminDImg from '../ass/assets/project/admin.jpg';
import cryptoImg from '../ass/assets/project/crypto.jpg'
import Drive from '../ass/assets/project/drive.jpg'
import Data from '../ass/assets/project/data.jpg'
import mealAppImg from '../ass/assets/project/meal-app.jpg'
import Defi from '../ass/assets/project/defi.jpg'
import SMS from '../ass/assets/project/sms.jpg'
import VTU from '../ass/assets/project/vtuu.jpg';
import '../index.css'; // Importing Tailwind CSS
import ProjectItem from './ProjectItem';

const Work = () => {
  const project = [
    
    { image: cryptoImg, name: 'Crypto App', github: 'https://cryptobase.abdulsalammuhammadabubakar.com.ng/', live: '' },
    { image: mealAppImg, name: 'Meal App', github: 'https://abdul100-han.github.io/react-meal-app/', live: '' },
    { image: Defi, name: 'DEFI', github: 'https://abdul100-han.github.io/defi-react-website', live: '' },
    { image: Drive, name: 'DRIVE', github: 'https://abdul100-han.github.io/react-car-website', live: '' },
    { image: Data, name: 'Data Seucred Protection', github: 'https://datasecure.abdulsalammuhammadabubakar.com.ng', live: '' },
   
    { image: AdminDImg, name: 'Admin Dashboard', github: 'https://abdul100-han.github.io/ecommerce', live: '' },
    { image: SMS, name: 'School Management System', github: 'https://newmethodsacademyzaria.com.ng', live: '' },
    { image: VTU, name: 'VTU Website', github: 'https://aruscodata.com', live: '' },
  ];

  return (
    <div id='projects' className='max-w-[1240px] w-full h-full mx-auto p-2 flex justify-center items-center'>
      <div className='max-w-[1000px] mx-auto p-4 flex flex-col justify-center w-full h-full'>
        <div className='pb-8'>
        <p className='text-xl tracking-widest uppercase text-[#5651e5]'>
          Projects
        </p>
        <h2 className='py-4'>What I&apos;ve Built</h2>
        
        </div>

        <div className="grid sm:grid-cols-2 md:grid-cols-2 gap-4"> {/* Change md:grid-cols-3 to md:grid-cols-2 */}
          {project.map((item, index) => (
            <div
              key={index}
              style={{ backgroundImage: `url(${item.image})` }}
              className="shadow-lg shadow-[#040c16] group container rounded-md flex justify-center text-center items-center mx-auto content-div hover:bg-gradient-to-r hover:from-[#709dff] hover:to-[#709dff] hover:bg-opacity-80"
            >
              <div className="opacity-0 group-hover:opacity-100">
                <span className="text-2xl font-bold text-white tracking-wider">
                  {item.name}
                </span>
                <div className="pt-8 text-center">
                  <a href={item.github} target="_blank" rel="noopener noreferrer">
                    <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      More info
                    </button>
                  </a>
                  <a href={item.live} target="_blank" rel="noopener noreferrer">
                    {/* <button className="text-center rounded-lg px-4 py-3 m-2 bg-white text-gray-700 font-bold text-lg">
                      Live
                    </button> */}
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Work;
